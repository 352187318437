import * as React from "react";
import './ThankYou.css';
import logoSVG from "../../assets/logosvg.svg"
import { /*useLocation,*/ useNavigate } from 'react-router-dom';

function ThankYou(props) {
    const navigate = useNavigate();

    const handleHomeClick = (e) => {
        navigate("/home");
    }

    return (
        <div className="thankyou-wrap">
            <div className="thankyou-header">
                <img className="adin-logo" src={logoSVG} alt="adinqra-logo" onClick={handleHomeClick} />
            </div>
            <div
                name="thankyou"
                id="thankyou"
                className="thankyou-form"
            >
                <p className="text-lg mb-2">Thank you for your feedback!</p>

                <button className="thankyou-btn mx-auto mt-4"
                    type="submit"
                    id="thankyou-submit"
                    onClick={handleHomeClick}
                >
                    Return to Home
                </button>
            </div>
        </div>
    );
}

export default ThankYou;