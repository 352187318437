import { useState, useEffect } from 'react';
import "./Navigation.css";
import { GiHamburgerMenu } from 'react-icons/gi';
import { AiOutlineClose } from 'react-icons/ai';
import { useLocation, useNavigate } from 'react-router-dom';
import $ from 'jquery';
// import logo from "../../assets/logo.webp"


function Navigation(props) {
    const navigate = useNavigate();
    const location = useLocation();
    const { pathname } = location;
    const [pathNameVar, setPathNameVar] = useState("home");
    const [toggleMenu, setToggleMenu] = useState(false);

    useEffect(() => {
        let pathSubstr = pathname.substring(1, pathname.length);

        setPathNameVar(pathSubstr);

        $("*").removeClass("active");

        if (pathname.length > 1) {
            $('#' + pathSubstr).addClass("active");
            $('#' + pathSubstr + "small").addClass("active");
        }
    }, [pathname, toggleMenu]);

    const updateNavigation = () => {
        let pathSubstr = pathname.substring(1, pathname.length);

        setPathNameVar(pathSubstr);

        $("*").removeClass("active");

        if (pathname.length > 1) {
            $('#' + pathSubstr).addClass("active");
        }
    }

    const handleToggleMenu = (e) => {
        setToggleMenu(!toggleMenu);
        updateNavigation();
    }

    // const handleLogoClick = (e) => {
    //     navigate("/home");
    // }

    const handleHomeClick = (e) => {
        handleToggleMenu();
        navigate("/home");
    }

    const handleFormClick = (e) => {
        handleToggleMenu();
        navigate("/contact");
    }

    const handleDisclosuresClick = (e) => {
        handleToggleMenu();
        navigate("/disclosures");
    }

    return (
        <nav className="navbar-top">

            {/* {!toggleMenu ? ( */}
            <div className='nav-interwrap' style={{ display: (pathNameVar === "home" || pathNameVar === "feedback" || pathNameVar === "thankyou" ? "none" : "") }}>
                {/* <div className='logo-title-wrap' onClick={handleLogoClick}>
                    <img className="logo" src={logo} alt="adinqra-logo" />
                    <h1>Adinqra</h1>
                </div> */}

                <ul className='navlist'>
                    <li id="home" className='navlist-item' onClick={handleHomeClick}>home</li>
                    {/* <li className='navlist-spacer'>-</li> */}
                    <li id="contact" className='navlist-item' onClick={handleFormClick}>contact</li>
                    {/* <li className='navlist-spacer'>-</li> */}
                    <li id="disclosures" className='navlist-item' onClick={handleDisclosuresClick}>disclosures</li>
                </ul>
            </div>
            {/* ) : null} */}


            <div className="app__navbar-smallscreen nav-interwrap" style={{ display: (pathNameVar === "home" || pathNameVar === "feedback" || pathNameVar === "thankyou" ? "none" : "") }}>
                {/* <div className='logo-title-wrap' onClick={handleLogoClick}>
                    <img className="logo" src={logo} alt="adinqra-logo" />
                    <h1>Adinqra</h1>
                </div> */}

                <GiHamburgerMenu className="hamburger" visibility={toggleMenu ? 'hidden' : 'visible'} onClick={handleToggleMenu} />
                {/* {toggleMenu ? ( */}
                <div className="app__navbar-smallscreen_overlay slide-bottom" style={{ display: (toggleMenu ? "" : "none") }}>
                    <AiOutlineClose fontSize={34} className="overlay__close" onClick={handleToggleMenu} />
                    <div className="app__navbar-smallscreen_links">
                        <li id="homesmall" className='small-navlist-item' onClick={handleHomeClick}><div className='small'>home</div></li>
                        <li id="contactsmall" className='small-navlist-item' onClick={handleFormClick}><div className='small'>contact</div></li>
                        <li id="disclosuressmall" className='small-navlist-item' onClick={handleDisclosuresClick}><div className='small'>disclosures</div></li>
                    </div>
                </div>
                {/* ) : null} */}
            </div>
        </nav>
    );
}

export default Navigation;