import * as React from "react";
import { useEffect } from "react";
import './Home.css';
import { useNavigate } from 'react-router-dom';
import logoSVG from "../../assets/logosvg.svg"
import $ from 'jquery';

function Home(props) {
    const navigate = useNavigate();

    useEffect(() => {
        function getRandomArbitrary(min, max) {
            return Math.random() * (max - min) + min;
        }

        const handleAnimationSwap = () => {
            // let rh = getRandomArbitrary(-175, 250);
            let vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
            let vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)
            let rh = getRandomArbitrary(-80, 80);

            // For mobile
            if (vh > vw) {
                rh = getRandomArbitrary(-85, 35);
                // rh = -85;
            }
            // For desktop
            else {
                rh = getRandomArbitrary(-120, 60);
                // rh = 60;
            }

            // console.log("H: " + vh + "W: " + vw)
            // console.log("rval: " + rh + "px")

            $("#background-movement-overlay").removeClass("animate-class");
            $("#background-movement-overlay").width();
            $("#background-movement-overlay").addClass("animate-class");
            $("#background-movement-overlay").width();
            // $("#background-movement-overlay").css("background-position", "center " + rh + "vh");
            $("#background-movement-overlay").css("top", rh + "vh");
        }

        var bgElement = document.getElementById("background-movement-overlay")
        bgElement.addEventListener('animationend', (event) => {
            handleAnimationSwap();
        });
    }, []);

    // const handleOnClick = (e) => {
    //     navigate("/form");
    // }

    const handleHomeClick = (e) => {
        navigate("/home");
    }

    const handleFormClick = (e) => {
        navigate("/contact");
    }

    const handleDisclosuresClick = (e) => {
        navigate("/disclosures");
    }


    return (
        <div className="home-wrap">

            <div className="background-movement animate-class" id="background-movement-overlay"></div>

            <div className="path-list-wrap">
                <div></div>
                <div className='pathlist'>
                    <div className="fit-active">
                        <div className="bruh"></div>
                        <div id="home" className='pathlist-item fake-active' onClick={handleHomeClick}>home</div>
                    </div>
                    {/* <li className='navlist-spacer'>-</li> */}
                    <div id="contact" className='pathlist-item' onClick={handleFormClick}>contact</div>
                    {/* <li className='navlist-spacer'>-</li> */}
                    <div id="disclosures" className='pathlist-item' onClick={handleDisclosuresClick}>disclosures</div>
                </div>

                <img className="logo-home" src={logoSVG} alt="adinqra-logo" />
            </div>
            {/* <div className="landing-wrap">
                <div className="home-landing">
                    <div className="welcome-text">Welcome to <b className="adinqra-emphasis">ADINQRA</b></div>
                </div>

                <div className="get-started-wrap">
                    <b className="please-fill">{"> "}Please fill out our questionnaire</b>

                    <button className="get-started-btn" onClick={handleOnClick}>Get Started</button>
                </div>
            </div> */}

        </div >
    );
}

export default Home;