import * as React from "react";
import './Disclosures.css';
import logoSVG from "../../assets/logosvg.svg"
import '@react-pdf-viewer/page-navigation/lib/styles/index.css';

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import TOS1 from "../../assets/TOSAssets/TermsofService1.png"
import TOS2 from "../../assets/TOSAssets/TermsofService2.png"
import PP1 from "../../assets/TOSAssets/PrivacyPolicy1.png"
import PP2 from "../../assets/TOSAssets/PrivacyPolicy2.png"

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

function TermsOfService(props) {

    return (
        <div className="disclosures-wrap">
            <div className="disclanding-wrap">
                <div className="disclosures-landing">
                    <div className="disc-text">
                        {/* Adinqra's Disclosures */}
                        <img className="disc-logo" src={logoSVG} alt="adinqra-logo" />
                        <p className="font-bold">Privacy Policy</p>
                    </div>
                    <hr className="extendbar"></hr>
                </div>

                <Carousel
                    preventMovementUntilSwipeScrollTolerance={true}
                    swipeScrollTolerance={7}
                >
                    <div>
                        <img src={PP1} alt='pp1' />
                        {/* <p className="legend">Page 1</p> */}
                    </div>
                    <div>
                        <img src={PP2} alt="pp2" />
                    </div>
                </Carousel>
                
                {/* <button class="bg-red-300 mx-auto w-min hover:bg-red-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center">
                    <svg class="fill-current w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z"/></svg>
                    <span>Download</span>
                </button> */}

                <hr class="w-full mb-4 mt-4 h-1 mx-auto my-0 bg-stone-300 border-0 rounded md:my-4" />

                
                <div className="disclosures-landing">
                    <div className="disc-text">
                        {/* Adinqra's Disclosures */}
                        <img className="disc-logo" src={logoSVG} alt="adinqra-logo" />
                        <p className="font-bold">Terms of Service</p>
                    </div>
                    <hr className="extendbar"></hr>
                </div>

                <Carousel
                    preventMovementUntilSwipeScrollTolerance={true}
                    swipeScrollTolerance={7}
                >
                    <div>
                        <img src={TOS1} alt="tos1" />
                        {/* <p className="legend">Page 1</p> */}
                    </div>
                    <div>
                        <img src={TOS2} alt="tos2"/>
                    </div>
                </Carousel>

                {/* <div className="mt-30">

                </div> */}

                {/* <button class="bg-gray-300 mx-auto w-min hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center">
                    <svg class="fill-current w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z"/></svg>
                    <span>Download</span>
                </button> */}



                {/* <button class="bg-red-300 mx-auto w-min hover:bg-red-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center">
                    <svg class="fill-current w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z"/></svg>
                    <span>Download</span>
                </button> */}


                {/* </div> */}

                {/* <hr class="w-full mb-4 mt-4 h-1 mx-auto my-0 bg-stone-300 border-0 rounded md:my-4" /> */}


                {/* <div className="comingsoon">Coming soon</div> */}
                {/* <div className="disc-section">

                    <button className="sign-btn" onClick={openDiscLink}>
                        Sign
                    </button>
                </div> */}
            </div>
        </div>
    );
}

export default TermsOfService;